import './App.css';
import { useRef } from 'react';
import { useState } from 'react';


async function getsurl(url) {
  const response = await fetch(url);
  const data = await response.json();
  return data
}


function App() {
  const refObject = useRef("")
  const [text, setText] = useState("");

  const [finish, setFinish] = useState(false);

  const [suc, setSuc] = useState(true);

  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(text);
  };



  const geturl = () => {

    const url = refObject.current.value
    if (url === "") {
      return
    }
    const get = "https://test.urlshort.workers.dev/post?q=" + url
    const s = getsurl(get)
    s.then(value => {
      setFinish(true)
      setSuc(true)
      setText(value.Url)
    })
    s.catch(err => {
      setFinish(true)
      setSuc(false)
      setText(err)
    })
  }



  return (
    <div className="App">
      <h2>URLを入力</h2>
      <input
        type="url"
        ref={refObject}
        id="text1"
      ></input>
      <button
        onClick={() => geturl()}
        className='btn1'
      ><p>短く</p></button>
      <div>
        {
          finish ? (
            suc ? (
              <>
                <p>短いurlは</p>
                <p>{text === undefined ? "生成できませんでした" : text}</p>
              </>
            ) : (
              <>
                <p>うまく行きません</p>
                <p>{text}</p>
              </>
            )
          ) : null
        }
        {
          finish ? (
            suc ? (<>{
              text === undefined ? null : (
                <>
                  <button
                    onClick={() => copyToClipboard()}
                    className='btn2'
                  >コピー</button>

                  <button
                    onClick={() => copyToClipboard()}
                    className='btn3'
                  >
                    <a
                      href={text}
                      target="_blank"
                      rel="noopener noreferrer"

                    >
                      <p>

                        コピーして
                      </p>
                      <p>

                        リンクに飛ぶ
                      </p>

                    </a>
                  </button>
                </>
              )
            }


            </>) : null
          ) : null
        }
      </div>
    </div>
  );
}

export default App;
